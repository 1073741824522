const accountRoutes = [
  {
    path: "/account",
    name: "account",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "@/views/account/AccountView.vue"
      ),
  },
];

export default accountRoutes;
