const menu = {
  dashboard: "Dashboard",
  scanResult: "Scan Result",
  management: "Management",
  account: "Account",
  support: "Support",
  dashboard1: "Dashboard 1",
  dashboard2: "Dashboard 2",
  whiteList: "White List",
  blackList: "Black List",
  permutation: "Permutation",
  pageTitle: "Page Title",
  pattern: "Pattern",
  report: "Report",
  contact: "Contact",
  mypage: "MyPage",
  logout: "Logout",
};

export default menu;
