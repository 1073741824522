const alert = {
  complete_coppy: "Copy completed.",
  must_enter_email: "Must enter your email.",
  must_enter_password: "Must enter your password.",
  password_mismatch: "Password don't match.",
  must_enter_old_password: "You must enter your old password.",
  must_enter_new_password: "Must enter a new password.",
  password_reset: "Password reset has been completed.",
  new_password_equal: "The new password is the same as the old password.",
  complete_singup: "Sign up is complete.",
  mail_sent: "Mail has been sent.",
  invalid_password_format: "The password format is invalid.",
  already_exists: "Account already exists.",
  internal_error_occurred: "An internal error({status}) occurred.",
  try_again_in_a_few_minutes: "Please try again later.",
  error_contact_admin: "Please contact the administrator.",
  check_email: "Please check the email sent.",
  invalid_email: "Not a valid email address",
  must_enter_confirm_password: "Must enter password again.",
  email_not_exist: "Email does not exist",
  id_locked: "Account is locked.",
  id_expired: "Activation ID has expired",
  lock_id_expired: "Unlock ID has expired.",
  id_not_active: "Account has not been activated.",
  already_active: "Account is already activated.",
  invalid_unlock_id: "Unlock ID is invalid.",
  unlock_id_not_exist: "Unlock ID does not exist.",
  invalid_request: "Request is invalid.",
  // 비밀번호 재설정
  expired_reset_id: "Password reset ID has expired.",
  reset_id_does_not_exist: "Password reset ID does not exist.",
  // 신고하기
  must_enter_url: "Must enter the URL address.",
  must_agree_use: "Must agree to the collection of personal information.",
  report_submitted: "Your report has been submitted.",
  // 문의 하기
  must_enter_company_name: "Must enter the company name/individual.",
  must_enter_name: "Must enter your name.",
  must_enter_phone_number: "Must enter your mobile phone number.",
  must_enter_inquiry: "Must enter your inquiry.",
  contract_received: "Your inquiry has been submitted.",
  submission_fail: "Submission failed",
  //유효성 검사
  invalid_url: "Invalid URL format.",
  invalid_qr: "Invalid QR Code.",
  enter_search_query: "Please enter a search query.",
  // 사용횟수 초과
  exceed_limit: "You have exceeded your usage limit.",
  login_to_use_feature: "Please login to use this feature.",
  url_id_not_exist: "URL ID does not exist.",
  request_timeout: "The Request has timed out.",
  //searchbar
  service_not_available: "Service is under preparation.",
  mobile_only_feature: "This feature is only available on mobile devices.",
  camera_init_error: "Camera initialization error occurred.",
  //마이페이지

  nickname_change_completed: "Nickname change completed.",
  invalid_request_or_password_mismatch:
    "Request information is invalid or the passwords do not match.",
  user_id_not_exist: "User ID does not exist.",
  server_error: "A server error occurred. Please try again later.",
  unknown_error: "An unknown error occurred.",
  nickname_change_error: "An error occurred while changing the nickname.",
  password_change_completed: "Password change completed.",
  new_password_same_as_current:
    "The new password is the same as the current password.",
  password_change_error: "An error occurred while changing the password.",
};

export default alert;
