import { Certificate } from "crypto";

const search = {
  tab: {
    sms: "SMS Messages",
    qr: "QR Code(Image)",
  },
  summary: {
    analysis: "Analysis",
    scan_at: "Last scan date",
    status: "Status",
    tags: "Tags",
    issuer: "Issuer",
    subject: "Subject",
    not_before: "Date of issue",
    not_after: "Expiration date",
    version: "TLS Version",
    judgment_basis: "Basis for final judgment(By ‘Generative AI’)",
    analysis_by_engine: "Analysis results and judgment basis for each engine",
    detection: "Detection Information by Engine",
    detection_invaild_id: "The item ID information is invalid.",
    screenshot: "Screenshot",
    doamin_title: "Domain",
    domain: "Domain Name",
    registered_date: "Registration date",
    registrant: "Registrant",
    registrant_address: "Registrant address",
    updated_date: "Updated date",
    expiration_date: "Expiration date",
    name_servers: "Name server",
    ip_address: "IP address",
    nation: "Nation",
    host_name: "Host name",
    screenshot_none: "There are no screenshot images.",
    host_ip_none: "Host IP information does not exist.",
    domain_none: "Domain information does not exist.",
    certificate_none: "Certificate information does not exist.",
    detection_none: "Detection information does not exist.",
    hash_none: "Hash information does not exist.",
    certificate_title: "Certificate",
    show_brief: "Show Brief",
    show_all: "Show All",
  },
  resources: {
    tips: "It shows the Domain (URL) information containing the hash file.",
  },
  scanning: {
    url_scanning: "Scanning URL.",
    wait_a_moment: "please wait for a moment.",
  },
};

export default search;
