import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";

const routes: Array<RouteRecordRaw> = [
  ...homeRoutes,
  ...loginRoutes,
  {
    path: "/:catchAll(.*)",
    redirect: {
      name: "home",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
