const alert = {
  complete_coppy: "복사가 완료되었습니다.",
  //계정
  must_enter_email: "이메일을 입력해야 합니다.",
  must_enter_password: "비밀번호를 입력해야 합니다.",
  password_mismatch: "비밀번호가 일치하지 않습니다.",
  must_enter_old_password: "기존 비밀번호를 입력해야 합니다.",
  must_enter_new_password: "새 비밀번호를 입력해야 합니다.",
  password_reset: "비밀번호 재설정이 완료되었습니다.",
  new_password_equal: "새비밀번호가 기존 비밀번호와 동일합니다.",
  mail_sent: "메일이 발송되었습니다.",
  complete_singup: "회원 가입이 완료되었습니다.",
  invalid_password_format: "비밀번호 형식이 유효하지 않습니다.",
  already_exists: "사용자 계정이 이미 존재합니다",
  internal_error_occurred: "내부 오류({status})가 발생하였습니다.",
  try_again_in_a_few_minutes: "잠시 후 다시 시도해주시기 바랍니다.",
  error_contact_admin: "관리자에게 문의바랍니다.",
  check_email: "발송된 이메일을 확인해주세요.",
  invalid_email: "유효한 이메일 주소가 아닙니다.",
  must_enter_confirm_password: "비밀번호를 한번 더 입력해야 합니다.",
  email_not_exist: "이메일이 존재하지 않습니다.",
  id_locked: "계정이 잠긴 상태입니다.",
  id_expired: "활성화 ID가 만료되었습니다.",
  lock_id_expired: "잠금해제 ID가 만료되었습니다.",
  id_not_active: "계정이 활성화 되지 않았습니다.",
  already_active: "계정이 이미 활성화 상태입니다.",
  invalid_unlock_id: "잠금 해제 ID가 유효하지 않습니다.",
  unlock_id_not_exist: "잠금 해제 ID가 존재하지 않습니다.",
  invalid_request: "요청 정보가 유효하지 않습니다.",
  // 비밀번호 재설정
  expired_reset_id: "비밀번호 재설정 정보가 만료되었습니다.",
  reset_id_does_not_exist: "비밀번호 재설정 정보가 존재하지 않습니다.",
  // 신고하기
  must_enter_url: "URL 주소를 입력해야 합니다.",
  must_agree_use: "개인정보 수집에 동의하여야 합니다.",
  report_submitted: "신고 접수가 완료되었습니다.",
  // 문의하기
  must_enter_company_name: "회사명 / 개인을 입력해야 합니다.",
  must_enter_name: "이름을 입력해야 합니다.",
  must_enter_phone_number: "핸드폰 번호를 입력해야 합니다.",
  must_enter_inquiry: "문의 내용을 입력해야 합니다.",
  contract_received: "문의 접수가 완료되었습니다.",
  submission_fail: "제출 실패",
  //유효성 검사
  invalid_url: "유효하지 않은 URL 형식입니다.",
  invalid_qr: "유효하지 않은 QR Code 입니다.",
  enter_search_query: "검색어를 입력하세요.",
  // 사용횟수 초과
  exceed_limit: "사용 한도를 초과하였습니다.",
  login_to_use_feature: "잠시 후에 사용해 주시기 바랍니다.",
  url_id_not_exist: "존재하지 않는 URL ID입니다.",
  request_timeout: "요청 시간이 초과되었습니다.",
  //searchbar
  service_not_available: "서비스 준비중입니다.",
  mobile_only_feature: "모바일에서 사용 가능한 기능입니다.",
  camera_init_error: "카메라 초기화 오류가 발생했습니다.",
  //마이페이지
  nickname_change_completed: "닉네임 변경이 완료되었습니다.",
  invalid_request_or_password_mismatch:
    "요청 정보가 유효하지 않거나 비밀번호가 일치하지 않습니다.",
  user_id_not_exist: "사용자 ID가 존재하지 않습니다.",
  server_error: "서버 오류가 발생했습니다. 나중에 다시 시도해 주세요.",
  unknown_error: "알 수 없는 오류가 발생했습니다.",
  nickname_change_error: "닉네임 변경 중 오류가 발생했습니다.",
  password_change_completed: "비밀번호 변경이 완료되었습니다.",
  new_password_same_as_current: "새 비밀번호와 기존 비밀번호가 동일합니다.",
  password_change_error: "비밀번호 변경 오류가 발생했습니다.",
};

export default alert;
