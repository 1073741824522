import {
  SigninRequest,
  User,
  PatchTermsRequest,
  ForgotRequest,
  ResetPasswordRequest,
} from "@/types/auth";
import { GetError, RestClient } from "./restclient";
import { AuthToken, RefreshParams, ApiKeyDetails } from "@/types/user";
export const authApi = {
  //로그인
  Signin: async (sr: SigninRequest): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.post<User>(`/users/auth`, sr);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  //로그아웃
  Logout: async (tid: string): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.delete<string>(
        `/users/auth/${tid}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  //비밀번호 찾기 (아이디/기존비밀번호/새비밀번호)
  ForgotPassword: async (fr: ForgotRequest): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/password/forgot`,
        fr
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  //토큰 갱신
  Refresh: async (
    accessJwt: string | null,
    refreshJwt: string | null
  ): Promise<[number, AuthToken | string]> => {
    try {
      if (!accessJwt) accessJwt = "";
      if (!refreshJwt) refreshJwt = "";
      const params: RefreshParams = {
        access_jwt: accessJwt,
        refresh_jwt: refreshJwt,
      };
      const { data, status } = await RestClient.put<AuthToken>(
        `/users/auth`,
        params
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  PatchTermsByEmail: async (
    req: PatchTermsRequest
  ): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.patch<User>(
        `/users/terms`,
        req
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 비밀번호 재설정 ID를 통한 사용자 정보 조회
  GetByResetId: async (rid: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/password/reset/${rid}`
      );
      console.log(rid);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  //비밀번호 재설정 (리셋아이디/새비밀번호)
  ResetPassword: async (
    id: string,
    rpr: ResetPasswordRequest
  ): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.patch<User>(
        `/users/password/reset/${id}`,
        rpr
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 닉네임 변경
  UpdateUserName: async (
    accessToken: {
      email: string;
      platform: string;
    },
    name: string
  ): Promise<[number, User | string]> => {
    try {
      const requestBody = {
        email: accessToken.email,
        name,
        platform: accessToken.platform,
      };

      const { data, status } = await RestClient.patch<User>(
        `/users`,
        requestBody
      );

      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // API Key 조회
  GetApiKeyDetails: async (
    apiKey: string
  ): Promise<[number, ApiKeyDetails | string]> => {
    try {
      const { data, status } = await RestClient.get<ApiKeyDetails>(
        `/users/apikeys/${apiKey}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
};
