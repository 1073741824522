const supportRoutes = [
  {
    path: "/support",
    name: "support",
    component: () =>
      import(
        /* webpackChunckName: "support" */ "@/views/support/SupportView.vue"
      ),
  },
];

export default supportRoutes;
