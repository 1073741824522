import newsRoutes from "./newsRoutes";
import managementRoutes from "./managementRoutes";
import accountRoutes from "./accountRoutes";
import supportRoutes from "./supportRoutes";
import scanResultsRoutes from "./scanRoutes";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "@/store/auth";

const homeRoutes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunckName: "home" */ "@/views/HomeView.vue"),
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();

      await authStore.refreshAuthToken();

      if (!authStore.accessJwt) {
        return next({ name: "login" });
      }
      return next();
    },
    children: [
      {
        path: "",
        name: "default",
        redirect: "/dashboard/dashboard1",
      },
      {
        path: "dashboard/dashboard1",
        name: "dashboard1",
        component: () =>
          import(
            /* webpackChunckName: "dashboard1" */ "@/views/dashboard/DashboardView1.vue"
          ),
      },
      {
        path: "dashboard/dashboard2",
        name: "dashboard2",
        component: () =>
          import(
            /* webpackChunckName: "dashboard2" */ "@/views/dashboard/DashboardView2.vue"
          ),
      },
      ...newsRoutes,
      ...scanResultsRoutes,
      ...managementRoutes,
      ...accountRoutes,
      ...supportRoutes,
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/dashboard/dashboard1",
  },
];

export default homeRoutes;
