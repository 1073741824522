import {
  LinkRowsInfo,
  ResourceInfoRowsInfo,
  ScanResult,
  ScanResultRowsInfo,
  ScanSummaryResult,
  SearchParam,
  UrlRequest,
  DetectionInfo,
} from "@/types/search";
import { GetError, RestClient } from "./restclient";
import { AxiosHeaderValue, AxiosResponseHeaders } from "axios";
import { useAuthStore } from "@/store/auth";

export const summaryApi = {
  GetScanSummaryResultByUrlId: async (
    url_id: string
  ): Promise<[number, ScanSummaryResult | string]> => {
    try {
      const apikey = useAuthStore().access_id;
      let config = {};
      if (apikey) {
        config = {
          headers: {
            "X-APIKEY": apikey,
          },
        };
      }
      const { data, status } = await RestClient.get<ScanSummaryResult>(
        `/scanresults/${url_id}/summary`,
        config
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetScreenshotByUrlId: async (
    url_id: string
  ): Promise<
    [
      number,
      string,
      (
        | AxiosResponseHeaders
        | Partial<{
            Server: AxiosHeaderValue;
            "Content-Type": AxiosHeaderValue;
            "Content-Length": AxiosHeaderValue;
            "Cache-Control": AxiosHeaderValue;
            "Content-Encoding": AxiosHeaderValue;
          }>
      )
    ]
  > => {
    try {
      const { data, status, headers } = await RestClient.get<string>(
        `/scanresults/${url_id}/screenshot`,
        {
          responseType: "blob",
        }
      );
      return [status, data, headers];
    } catch (e: unknown) {
      const errorCode = 500;
      const errorMessage = "An error occurred";
      const errorHeaders = {};
      return [errorCode, errorMessage, errorHeaders];
    }
  },

  GetResourcesByUrlId: async (
    sp: SearchParam
  ): Promise<[number, ResourceInfoRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ResourceInfoRowsInfo>(
        `/urls/resources/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  SearchResources: async (
    sp: SearchParam
  ): Promise<[number, ResourceInfoRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ResourceInfoRowsInfo>(
        `/urls/resources/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetLinksByUrlId: async (
    sp: SearchParam
  ): Promise<[number, LinkRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<LinkRowsInfo>(
        `/urls/links/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetRealtimeScanResult: async (): Promise<[number, ScanResult[] | string]> => {
    try {
      const { data, status } = await RestClient.get<ScanResult[]>(
        `/urls/latest`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  SearchScanResultByResource: async (
    sp: SearchParam
  ): Promise<[number, ScanResultRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ScanResultRowsInfo>(
        `/urls/search/resources`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetResourceHashInfo: async (
    hashId: string
  ): Promise<
    [number, { size: number; content_type: string; type: string } | string]
  > => {
    try {
      const { data, status } = await RestClient.get<{
        size: number;
        content_type: string;
        type: string;
      }>(`/urls/resources/${hashId}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetUrlStatus: async (
    req: UrlRequest
  ): Promise<[number, boolean | string]> => {
    try {
      const { data, status } = await RestClient.post<boolean>(
        `/scanresults/url/status`,
        req
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetWhitelistInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/whitelist/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetBlacklistInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/blacklist/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetPermutationInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/permutation/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetPatternInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/patterns/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetScanResultInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/scanresults/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
};
