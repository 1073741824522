const newsRoutes = [
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news/NewsView.vue"),
  },
];

export default newsRoutes;
