const alert = {
  complete_coppy: "コピーが完了しました。",
  must_enter_email: "メールを入力してください。",
  must_enter_password: "パスワードを入力してください",
  password_mismatch: "パスワードが一致しません。",
  must_enter_old_password: "既存のパスワードを入力してください。",
  must_enter_new_password: "新しいパスワードを入力してください。",
  password_reset: "パスワードのリセットが完了しました。",
  new_password_equal: "新しいパスワードは既存のパスワードと同じです。",
  complete_singup: "会員登録が完了しました。",
  mail_sent: "メールが送信されました。",
  invalid_password_format: "パスワード形式が無効です。",
  already_exists: "アカウントが存在しています",
  internal_error_occurred: "内部エラー({status})が発生しました。",
  try_again_in_a_few_minutes: "しばらくしてからもう一度お試しください。",
  error_contact_admin: "管理者にお問い合わせください。",
  check_email: "送信されたメールを確認してください。",
  invalid_email: "有効なメールアドレスではありません。",
  must_enter_confirm_password: "もう一度パスワードを入力してください。",
  email_not_exist: "メールは存在しません。",
  id_locked: "アカウントがロックされています。",
  id_expired: "有効化IDが期限切れです",
  lock_id_expired: "ロック解除IDが期限切れです。",
  id_not_active: "アカウントが有効になっていません。",
  already_active: "アカウントは既に有効になっています。",
  invalid_unlock_id: "ロック解除IDが無効です。",
  unlock_id_not_exist: "ロック解除IDが存在しません。",
  invalid_request: "リクエスト情報が無効です。",
  // 비밀번호 재설정
  expired_reset_id: "パスワードリセット IDの有効期限が切れました。",
  reset_id_does_not_exist: "パスワードリセットIDが存在しません。",
  // 신고하기
  must_enter_url: "URLアドレスを入力する必要があります。",
  must_agree_use: "個人情報の収集には同意が必要です。",
  report_submitted: "申告受付が完了しました。",
  // 문의 하기
  must_enter_company_name: "会社名/個人を入力してください。",
  must_enter_name: "名前を入力してください",
  must_enter_phone_number: "携帯番号を入力してください。",
  must_enter_inquiry: "お問い合わせ内容を入力する必要があります。",
  contract_received: "お問い合わせの受付が完了しました。",
  submission_fail: "送信失敗",
  //유효성 검사
  invalid_url: "無効なURL形式です。",
  invalid_qr: "無効なQRコードです。",
  enter_search_query: "検索語を入力してください。",
  // 사용횟수 초과
  exceed_limit: "使用制限を超過しました。",
  login_to_use_feature: "ログイン後にご利用ください。",
  url_id_not_exist: "存在しないURL IDです。",
  request_timeout: "リクエストがタイムアウトしました。",
  //searchbar
  service_not_available: "サービスは準備中です。",
  mobile_only_feature: "この機能はモバイルデバイスでのみ使用可能です。",
  camera_init_error: "カメラ初期化エラーが発生しました。",
  //마이페이지

  nickname_change_completed: "ニックネームの変更が完了しました。",
  invalid_request_or_password_mismatch:
    "リクエスト情報が無効であるか、パスワードが一致しません。",
  user_id_not_exist: "ユーザーIDが存在しません。",
  server_error: "サーバーエラーが発生しました。後で再試行してください。",
  unknown_error: "不明なエラーが発生しました。",
  nickname_change_error: "ニックネーム変更中にエラーが発生しました。",
  password_change_completed: "パスワード変更が完了しました。",
  new_password_same_as_current:
    "新しいパスワードが現在のパスワードと同じです。",
  password_change_error: "パスワード変更中にエラーが発生しました。",
};

export default alert;
